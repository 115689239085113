import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import { Typography } from '@mui/material';
import '../../assets/css/delivery-banner.css';

function DeliveryLocations() {
    const locations = [
        'Little Elm', 'Eldorado Parkway', 'Frisco', 'Carrollton',
        'Preston Rd', 'Frisco, Irving', 'Plano', 'McKinney'
    ];

    return (
        <Box className="delivery-location-container">
            {/* Available Delivery Locations Label */}
            <Box
                sx={{
                    backgroundColor: '#FFEB3B',
                    color: '#000',
                    fontWeight: 'bold',
                    padding: '4px 12px',
                    borderRadius: '4px',
                    marginRight: '16px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span> <RoomIcon />  </span>
                <Typography variant="body2" className="delivery-banner">
                    Available Delivery Locations</Typography>
                {/* <div className="delivery-banner">
                    Available Delivery Locations
                </div> */}
            </Box>

            {/* Location Chips */}
            {locations.map((location, index) => (
                <Chip
                    key={index}
                    label={location}
                    // icon={<RoomIcon />}
                    sx={{
                        marginRight: '8px',
                        backgroundColor: '#f4f4f4',
                        color: '#b71c1c',
                        border: '1px solid #b71c1c',
                        '& .MuiChip-icon': { color: '#b71c1c' }
                    }}
                />
            ))}
        </Box>
    );
}

export default DeliveryLocations;
