// CustomLocationSearch.js
import React, { useRef, useState, useEffect } from "react";
import {
    TextField,
    Paper,
    List,
    ListItem,
    Box,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import config from "../../utils/config";
import { extractAddress } from "../../utils/functions";

const apiKey = config.YOUR_GOOGLE_MAPS_API_KEY;

const CustomLocationSearch = ({ onLocationSelect, defaultValue = "", label = "Search location", showFindMyLocationIconVisible = true }) => {
    const [inputValue, setInputValue] = useState("");
    const [predictions, setPredictions] = useState([]);
    const autocompleteService = useRef(null);

    useEffect(() => {
        // Check if Google Maps API is loaded
        if (!window.google) return;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        setInputValue(defaultValue);
        // if (defaultValue) {
        //     setInputValue(defaultValue);
        // }
    }, [autocompleteService, defaultValue, setInputValue]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (value && autocompleteService.current) {
            autocompleteService.current.getPlacePredictions({
                input: value,
                componentRestrictions: { country: "us" }, // Restrict to US only
            }, (predictions) => {
                setPredictions(predictions || []);
            });
        } else {
            setPredictions([]);
        }
    };

    const handleSelectPrediction = (prediction) => {
        setInputValue(prediction.description);
        setPredictions([]);

        const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));
        placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const address = extractAddress(place);
                const locationLatLng = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    formatted_address: place.formatted_address,
                    ...address,
                };
                onLocationSelect(locationLatLng); // Send location data to parent
            }
        });
    };

    const findMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude, longitude } }) => {
                    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${latitude},${longitude}`;
                    fetch(geocodeUrl)
                        .then((response) => response.json())
                        .then((location) => {
                            const addressDetails = location.results[0];
                            const formatted_address = addressDetails.formatted_address;
                            const address = extractAddress(addressDetails);
                            onLocationSelect({
                                lat: latitude,
                                lng: longitude,
                                formatted_address,
                                ...address,
                            });
                        });
                }
            );
        }
    };

    return (
        <Box display="flex" alignItems="center" width="100%" position="relative" sx={{ marginBottom: '15px' }}>

            <TextField
                sx={{ flex: 1 }}
                label={label}
                variant="outlined"
                fullWidth
                value={inputValue}
                onChange={handleInputChange}
            />

            {predictions.length > 0 && (
                <Paper
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    <List>
                        {predictions.map((prediction) => (
                            <ListItem
                                button
                                key={prediction.place_id}
                                onClick={() => handleSelectPrediction(prediction)}
                            >
                                {prediction.description}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
            {
                showFindMyLocationIconVisible &&
                <MyLocationIcon sx={{ cursor: "pointer", ml: 1 }} onClick={findMyLocation} />
            }

        </Box>
    );
};

export default CustomLocationSearch;
