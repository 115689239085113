import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseModalButton = ({ onClick, top = "35px", right = "20px", zIndex = 99999, ...props }) => {
    return (
        <IconButton
            sx={{
                position: "absolute",
                top: top,
                right: right,
                zIndex: zIndex,
                ...props.sx // allows for additional styling overrides
            }}
            onClick={onClick}
            {...props} // allows passing other props like aria-label
        >
            <CloseIcon />
        </IconButton>
    );
};

export default CloseModalButton;
